import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

import webpageRoutes from './webpage';
import balnetRoutes from './balnet';

const routes: Array<RouteRecordRaw> = [
    webpageRoutes,
    balnetRoutes,
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
