import { RouteRecordRaw } from "vue-router";

import BmeAiLabNetView from '@/views/bmealnet/BalNetView.vue'

import AuthView from '@/views/bmealnet/AuthView.vue';

import DashboardView from '@/views/bmealnet/DashboardView.vue';

import WebpageView from '@/views/bmealnet/webpage/WebpageView.vue';
import PeopleView from "@/views/bmealnet/webpage/PeopleView.vue";
import ResearchView from "@/views/bmealnet/webpage/ResearchView.vue";
import KnowledgeView from "@/views/bmealnet/webpage/KnowledgeView.vue";
import PublicationView from "@/views/bmealnet/webpage/PublicationView.vue";

import ExperimentsView from "@/views/bmealnet/experiments/ExperimentsView.vue";
import MiBandView from "@/views/bmealnet/experiments/MiBandView.vue";

import CollaborationsView from '@/views/bmealnet/collaborations/CollaborationsView.vue';

import AdminView from '@/views/bmealnet/AdminView.vue';

import SettingsView from '@/views/bmealnet/SettingsView.vue';

import NotFound from '@/views/NotFound.vue';



const balnetRoutes: RouteRecordRaw = {
    path: '/bmealnet',
    name: 'BmeAiLabNet',
    component: BmeAiLabNetView,
    children: [
        {
            path: '',
            name: 'Dashboard',
            component: DashboardView,
            props: true,
        },
        {
            path: 'webpage',
            name: 'WebpageConfig',
            component: WebpageView,
            children: [
                {
                    path: 'research',
                    name: 'ResearchConfig',
                    component: ResearchView,
                    props: true,
                    children: [
                        {
                            path: '/:id',
                            name: 'ResearchConfigDetails',
                            component: ResearchView,
                            props: true,
                        },
                        {
                            path: '/create',
                            name: 'ResearchConfigNew',
                            component: ResearchView,
                            props: true,
                        },
                    ],
                },
                {
                    path: '',
                    name: 'PeopleConfig',
                    component: PeopleView,
                    props: true,
                    children: [
                        {
                            path: '/:id',
                            name: 'PeopleConfigDetails',
                            component: PeopleView,
                            props: true,
                        },
                        {
                            path: '/create',
                            name: 'PeopleConfigNew',
                            component: PeopleView,
                            props: true,
                        },
                    ],
                },
                
                {
                    path: 'knowledge',
                    name: 'KnowledgeConfig',
                    component: KnowledgeView,
                    props: true,
                },
                {
                    path: 'publication',
                    name: 'PublicationConfig',
                    component: PublicationView,
                    props: true,
                    children: [
                        {
                            path: '/:id',
                            name: 'ResearchConfigDetails',
                            component: ResearchView,
                            props: true,
                        },
                    ],
                }, 
            ],
        },
        {
            path: 'experiments',
            name: 'ExperimentsConfig',
            component: ExperimentsView,
            children: [
                {
                    path: 'miband',
                    name: 'MiBandView',
                    component: MiBandView,
                    props: true,
                    children: [
                        {
                            path: '/:id',
                            name: 'GuestDataView',
                            component: ResearchView,
                            props: true,
                        },
                    ],
                },
            ],
        },
        {
            path: 'collaborations',
            name: 'Collaborations',
            component: CollaborationsView,
            props: true,
        },
        {
            path: 'admin',
            name: 'Admin',
            component: AdminView,
            props: true,
        },
        {
            path: 'settings',
            name: 'Settings',
            component: SettingsView,
            props: true,
        },
    ],
};

export default balnetRoutes;