import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c4b057a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccessBar = _resolveComponent("AccessBar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["nav-container", { 'solid-nav': _ctx.isSolid, 'hidden-nav': _ctx.isHidden, }]),
    ref: "navContainer"
  }, [
    _createVNode(_component_AccessBar),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createTextVNode("Home")
        ]),
        _: 1
      }),
      _createTextVNode(" | "),
      _createVNode(_component_router_link, { to: "/research" }, {
        default: _withCtx(() => [
          _createTextVNode("Research")
        ]),
        _: 1
      }),
      _createTextVNode(" | "),
      _createVNode(_component_router_link, { to: "/people" }, {
        default: _withCtx(() => [
          _createTextVNode("People")
        ]),
        _: 1
      }),
      _createTextVNode(" | "),
      _createVNode(_component_router_link, { to: "/knowledgetransfer" }, {
        default: _withCtx(() => [
          _createTextVNode("Knowledge Transfer")
        ]),
        _: 1
      }),
      _createTextVNode(" | "),
      _createVNode(_component_router_link, { to: "/publication" }, {
        default: _withCtx(() => [
          _createTextVNode("Publication")
        ]),
        _: 1
      }),
      _createTextVNode(" | "),
      _createVNode(_component_router_link, { to: "/contactus" }, {
        default: _withCtx(() => [
          _createTextVNode("Contact Us")
        ]),
        _: 1
      }),
      _createTextVNode(" | ")
    ])
  ], 2))
}