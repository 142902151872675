
import { defineComponent } from 'vue';
import API from '@/api/public';
  
export default defineComponent({
    name: 'PeopleView',
  components: {
  },
  data() {
    let people: any[] = [];

    return {
      people: people,
    }
  },
  methods: {

    getAllPeople() {

        const params = {

        };

        // console.log("getAllPeople()", params);
        
        API.webpage.getAllPeople(params).then((response) => {
            
            console.log("response", response);

            if (response.meta.code == "200.00") {
                this.people = response.data.people;
            }

        }).catch((error) => {
            console.log("getAllPeople() error", error);
        });    
    }
  },
  mounted() {
    this.getAllPeople();
  },
})
