
import { defineComponent } from 'vue';
import NavBar from './components/NavBar.vue';
import FooterBar from './components/FooterBar.vue';

export default defineComponent({
  name: "App",
})
// @Options({
//   components: {
//     NavBar,
//   },
// })
// export default class Main extends Vue {}
