
import { defineComponent } from 'vue';
import API from '@/api/public';
  
export default defineComponent({
  components: {

  },
  methods: {

  },
})
