<template>
    <div class="footer-container">
        <div class="logo">
            <a href="https://www.polyu.edu.hk/bme/">
                <img src="../../assets/logo.png" />
            </a>
        </div>
        <div class="info">
            <p>Copyright &copy; 2023 Department of Biomedical Engineering AI lab, The Hong Kong Polytechnic University. All Rights Reserved.</p>
        </div>
    </div>
</template>

<style scoped lang="scss">
.footer-container {
    display: flex;
    flex-direction: column;
    height: 200px;
    width: 100%;
    border-top: 1px solid;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}

.logo {
    display: flex;
    max-width: 600px;
    width: 50vw;
    justify-content: center;
    margin-bottom: 30px;

    a {
        border: none;

        img {
            max-width: 100%; 
        }
    }
    
}

.info {
    display: flex;
    max-width: 1000px;
    width: 80vw;
    justify-content: center;
}


</style>
