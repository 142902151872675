
  import { defineComponent } from 'vue';
  import NavBar from '../../components/webpage/NavBar.vue';
  import FooterBar from '../../components/webpage/FooterBar.vue';
  
  export default defineComponent({
    name: "BmeAiLab",
    components: {
      NavBar,
      FooterBar,
    },
  })
  // @Options({
  //   components: {
  //     NavBar,
  //   },
  // })
  // export default class Main extends Vue {}
  