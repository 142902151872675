/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
const api_path = {
    // development
    // prod: "http://localhost:3000/api.bmeailab.io/anquav1.0/",
    
    // production
    prod: "https://www.polyu.edu.hk/bme/bme1d02/api.bmeailab.io/anquav1.0/",
};


const api = {

    webpage: {
        getAllPeople: (params: any) => {
            const request = {
                method: "public/webpage",
                params: params,
                query: "?action=people",
            };
            return get(request);
        },
    },
    balnet: {
        loginUser: (params: any) => {
            const request = {
                method: "public/balnet",
                params: params,
                query: "?action=user" +
                        "&username=" + params.username +
                        "&password=" + params.password,
            };
            return post(request);
        },
    },
};

export default api;


async function get(request: any) {

    return fetch(
        api_path.prod + request.method + request.query,
        {
            method: "GET",
            headers: {
                // Accept: "application/json",
            },
        }
    ).then((response) => response.json());
}
  
async function post(request: any) {

    return fetch(
        api_path.prod + request.method + request.query,
        {
            method: "POST",
            headers: {
                // Accept: "application/json",
                // "Content-Type": "application/json"
            },
            body: JSON.stringify(request.params),
        }
    ).then((response) => response.json());
}

async function put(request: any) {

    return fetch(
        api_path.prod + request.method + request.query,
        {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
        },
        body: JSON.stringify(request.params),
        }
    ).then((response) => response.json());
}