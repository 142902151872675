import { RouteRecordRaw } from "vue-router";

import BmeAiLabWebView from '@/views/webpage/MainView.vue'
import HomeView from '@/views/webpage/HomeView.vue';
import PeopleView from '@/views/webpage/people/PeopleView.vue';
import PeopleDetailView from  '@/views/webpage/people/PeopleDetailView.vue';
import ResearchView from '@/views/webpage/ResearchView.vue';
import PartnershipView from '@/views/webpage/Publication.vue';
import ContactView from '@/views/webpage/ContactView.vue';
import MaintenenceView from '@/views/webpage/MaintenenceView.vue';
import NotFound from '@/views/NotFound.vue';


const webpageRoutes: RouteRecordRaw = {
    path: '/',
    name: 'BmeAiLabWeb',
    component: BmeAiLabWebView,
    children: [
        {
            path: '',
            name: 'Home',
            component: HomeView,
        },
        {
            path: 'research',
            name: 'Research',
            component: MaintenenceView,
        },
        {
            path: 'people',
            name: 'People',
            component: PeopleView,
        },
        {
            path: 'people/:id',
            name: 'PeopleDetails',
            // component: PeopleDetailView,
            component: MaintenenceView,
        },
        {
            path: 'knowledgetransfer',
            name: 'KnowledgeTransfer',
            // component: PartnershipView,
            component: MaintenenceView,
        },
        {
            path: 'publication',
            name: 'Publication',
            // component: PartnershipView,
            component: MaintenenceView,
        },
        {
            path: 'contactus',
            name: 'ContactUs',
            // component: ContactView,
            component: MaintenenceView,
        },
        {
            path: '/:catchAll(.*)',
            name: 'NotFound',
            component: NotFound,
        },
    ],
};

export default webpageRoutes;