<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container">
                <div class="modal-header">
                    <slot name="header">
                        Form
                    </slot>

                    <button class="modal-default-button" @click="$emit('close')">
                        <span class="material-icons" style="font-size: 30px; font-weight: bold; color: darkred;">close</span>
                    </button>
                </div>  

                <div class="modal-body">
                    <slot name="body">
                        Body
                    </slot>
                </div>
<!-- 
                <div class="modal-footer">
                    Hello here's footer
                </div> -->
            </div>
        </div>
    </div>
</template>


<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
    display: flex;
    flex-direction: column;
    
    width: 80vw;
    min-width: 500px;
    height: 80vh;
    min-height: 300px;

    margin: 0px auto;
    padding: 30px 30px;
    background-color: #fff;
    border-radius: 25px 25px 25px 25px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);

    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
    display: flex;
    width: 100%;
    height: 50px;

    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;

    border: solid;
    border-radius: 5px 5px 5px 5px;
    
    background-color: #42b98380;
}

.modal-body {
    width: 100%;
    height: 100%;

    margin: 10px 0;
    overflow-y: scroll;

    border: solid;
    border-radius: 5px 5px 5px 5px;
}

.modal-footer {
  height: 20px;
}

.modal-default-button {
    float: right;
    display: flex;
    align-items: center;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from, .modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>

