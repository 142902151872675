
import { isString } from '@vue/shared';
import { defineComponent, ref } from 'vue';
import { onBeforeMount, onMounted, onBeforeUnmount, onUnmounted, reactive } from 'vue';
import AccessBar from './AccessBar.vue';

export default defineComponent({
  setup() {
    const state = reactive({

    });

    const navContainer = ref<HTMLDivElement>();

    return {
      navContainer,
    }

    // onMounted() {
    //   window.addEventListener
    // }
  },
  components: {
        AccessBar,
  },
  data() {
    // let navHeight = ref<number | undefined>(undefined);
    // let navContainer = ref<HTMLDivElement>();
    // const position = ref<number>();
    
    return {
      // scrollPotision: window.top?.scrollY,
      position: 0,
      // navContainer: HTMLDivElement,
      isSolid: false,
      isHidden: false,
    }
  },
  methods: {
    handleScroll(event: Event) {
      const currentPosition = window.top?.scrollY;
      const navHeight = this.navContainer?.clientHeight;
      // console.log("Solid", (navHeight as number)-80, currentPosition);
      // console.log("Hidden", this.position, currentPosition);
      // console.log("window", window.innerHeight, window.scrollY, currentPosition);

      if (currentPosition !== undefined && navHeight) { 
        if (currentPosition > 0 && currentPosition < window.innerHeight && currentPosition > this.position) {
          // console.log("hidden!!")
          this.isHidden = true;
        } else {
          // console.log("appear!!")
          this.isHidden = false;        
          if (currentPosition >= (navHeight - 80)) {
            // console.log("solid!!")
            this.isSolid = true;
          } else {
            // console.log("transparent!!")
            this.isSolid = false;
          }
        }
        this.position = currentPosition;
      }

    },
  },
  mounted() {
    console.log("Nav Bar mounted");
    // console.log(typeof(thi))
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  $refs: {
    navContainer: HTMLDivElement,
  }
})
