/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
const base_url = {
    // development
    // prod: "http://localhost:3000/api.bmeailab.io/anquav1.0/",
   
    // production
    prod: "https://www.polyu.edu.hk/bme/bme1d02/api.bmeailab.io/anquav1.0/",
};

export let TOKEN = '';
export const setToken = (token: string) => {
    TOKEN = token;
};

const api = {

    balnet: {
        loginUser: (params: any) => {
            const request = {
                method: "admin/balnet",
                params: params,
                query: "?action=user" +
                        "&username=" + params.username +
                        "&password=" + params.password,
            };
            return post(request);
        },

        webpage: {
            getAllPeople: (params: any) => {
                const request = {
                    method: "admin/balnet/webpage",
                    params: params,
                    query: "?action=people",
                };
                return get(request);
            },
            getAllPeoplePosition: (params: any) => {
                const request = {
                    method: "admin/balnet/webpage",
                    params: params,
                    query: "?action=people_posts",
                };
                return get(request);
            },
            getPersonById: (params: any) => {
                const request = {
                    method: "admin/balnet/webpage",
                    params: params,
                    query:  "?action=person" + 
                            "&id=" + params.id,
                };
                return get(request);
            },
            addPerson: (params: any) => {
                const request = {
                    method: "admin/balnet/webpage",
                    params: params,
                    query:  "?action=person",
                };
                return post(request);
            },
            updatePersonById: (params: any) => {
                const request = {
                    method: "admin/balnet/webpage",
                    params: params,
                    query:  "?action=person" +
                            "&id=" + params.id,
                };
                return put(request);
            },
        },

        experiments: {

            miband: {
                getAllExperiments: (params: any) => {
                    const request = {
                        method: "admin/balnet/experiments/miband",
                        params: params,
                        query: "?action=experiments",
                    };
                    return get(request);
                },

                getAllOrganizations: (params: any) => {
                    const request = {
                        method: "admin/balnet/experiments/miband",
                        params: params,
                        query: "?action=organizations",
                    };
                    return get(request);
                },

                getAllCenters: (params: any) => {
                    const request = {
                        method: "admin/balnet/experiments/miband",
                        params: params,
                        query: "?action=centers",
                    };
                    return get(request);
                },

                addExperiment: (params: any) => {
                    const request = {
                        method: "admin/balnet/experiments/miband",
                        params: params,
                        query: "?action=experiment",
                    };
                    return post(request);
                },
                
                
                getAllGuests: (params: any) => {
                    const request = {
                        method: "admin/balnet/experiments/miband",
                        params: params,
                        query: "?action=guests",
                    };
                    return get(request);
                },

                getParticipantById: (params: any) => {
                    const request = {
                        method: "admin/balnet/experiments/miband",
                        params: params,
                        query:  "?action=participant" +
                                "&id=" + params.id,
                    };
                    return get(request);
                },
                addGuest: (params: any) => {
                    const request = {
                        method: "admin/balnet/experiments/miband",
                        params: params,
                        query:  "?action=guest",
                    };
                    return post(request);
                },
                updateParticipantById: (params: any) => {
                    const request = {
                        method: "admin/balnet/experiments/miband",
                        params: params,
                        query:  "?action=participant" +
                                "&id=" + params.id,
                    };
                    return put(request);
                },
            },
        
        },

        users: {
            getAllUsers: (params: any) => {
                const request = {
                    method: "admin/balnet",
                    params: params,
                    query: "?action=users",
                };
                return get(request);
            },
            getUserById: (params: any) => {
                const request = {
                    method: "admin/balnet",
                    params: params,
                    query:  "?action=user" +
                            "&id=" + params.id,
                };
                return get(request);
            },
            getAllUsersClasses: (params: any) => {
                const request = {
                    method: "admin/balnet",
                    params: params,
                    query: "?action=users_classes",
                };
                return get(request);
            },
            addUser: (params: any) => {
                const request = {
                    method: "admin/balnet",
                    params: params,
                    query: "?action=user",
                };
                return post(request);
            },
            updateUserClassById: (params: any) => {
                const request = {
                    method: "admin/balnet",
                    params: params,
                    query: "?action=class",
                };
                return put(request);
            },
            updateUserPasswordById: (params: any) => {
                const request = {
                    method: "admin/balnet",
                    params: params,
                    query: "?action=password",
                };
                return put(request);
            },
        }
        
    },
};

export default api;


async function get(request: any) {

    return fetch(
        base_url.prod + request.method + request.query,
        {
            method: "GET",
            headers: {
                // Accept: "application/json",
                Authorization: `Bearer ${TOKEN}`,
            },
        }
    ).then((response) => 
        response.json()
    );
}
  
async function post(request: any) {

    if (request.params.file) {

        return fetch(
            base_url.prod + request.method + request.query,
            {
                method: "POST",
                headers: {
                    // "Accept": "application/json",
                    "Content-Type": 'multipart/form-data',
                    Authorization: `Bearer ${TOKEN}`,
                },
                body: request.params,
                // body: new FormData()
            }
        ).then((response) => 
            response.json()
        )
    }

    return fetch(
        base_url.prod + request.method + request.query,
        {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`,
            },
            body: JSON.stringify(request.params),
        }
    ).then((response) => 
        response.json()
    );
}

async function put(request: any) {

    return fetch(
        base_url.prod + request.method + request.query,
        {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${TOKEN}`,
        },
        body: JSON.stringify(request.params),
        }
    ).then((response) => 
        response.json()
    );
}