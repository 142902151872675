import { createApp } from 'vue';
import dotenv from 'dotenv';

import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

/* vuetify */
import 'vuetify/styles'
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';



// dotenv.config({ path: __dirname + process.env.NODE_ENV, });

const vuetify = createVuetify({
    components,
    directives,
});


/* formkit */
import { plugin, defaultConfig } from '@formkit/vue';
import '@formkit/themes/genesis';


/* vue app config */
const app = createApp(App)

// app.use(store)
app.use(router)

app.use(vuetify)
app.use(plugin, defaultConfig)

// app.component("modal", {
//     template: "#modal-template"
//   });

app.mount('#app')
